import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { AuthContext } from "../../context/GlobalContextProvider";
import api, { url } from "../../api";
import { navigate } from "gatsby";
import { Input, Form, Button, message } from "antd";
import UploadForm from "../predios/novoPredio/components/uploadForm";
import { LoadingOutlined } from "@ant-design/icons";
import PrediosSelect from "./components/prediosCarrossel";

export default function NossasLinhas() {
  const [page, setPage] = useState();
  const [loadingSave, setLoadingSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const context = useContext(AuthContext);

  useEffect(() => {
    getPage();
  }, []);

  const getPage = async () => {
    setLoading(true);
    const { data } = await api.get("/nossas-linhas");
    setPage(data);
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    setLoadingSave(true);
    var data = new FormData();
    values.fotoClub &&
      data.append(
        `files.fotoClub`,
        values.fotoClub[0]?.originFileObj,
        values.fotoClub[0]?.name
      );
    values.fotoDream &&
      data.append(
        `files.fotoDream`,
        values.fotoDream[0]?.originFileObj,
        values.fotoDream[0]?.name
      );
    values.fotoExclusivity &&
      data.append(
        `files.fotoExclusivity`,
        values.fotoExclusivity[0]?.originFileObj,
        values.fotoExclusivity[0]?.name
      );

    data.append(
      "data",
      JSON.stringify({
        textoDream: values.textoDream,
        textoClub: values.textoClub,
        textoExclusivity: values.textoExclusivity,
        conteudo: values.conteudo,
        predios: values.carousel,
      })
    );

    await api({
      method: "PUT",
      url: "/nossas-linhas",
      headers: {
        Authorization: `Bearer ${context?.token}`,
      },
      data,
    }).then((value) => {
      if (value) {
        message.success("Página salva com sucesso");
        navigate(`/nossasLinhas`);
      }
    });
    setLoadingSave(false);
  };

  // if (!context?.user && !context?.loading) {
  //   navigate(`/`);
  // }

  return (
    <Layout image="" loading={loading}>
      <SEO title="Nossas Linhas" />
      <Form onFinish={handleSubmit}>
        <PrediosSelect
          carrossel
          initialValue={page?.predios}
          {...{ name: "carousel", Form }}
        />
        <center>
          <h2>Nossas Linhas</h2>
        </center>
        <Form.Item name="conteudo" initialValue={page?.conteudo}>
          <Input.TextArea className="mt-5" />
        </Form.Item>
        <div
          className="mt-16 py-8 px-10 border-solid border-4 border-blue-300"
          style={{ borderRadius: 20 }}
        >
          <center>
            <h3>LINHA BIVVER DREAM</h3>
          </center>
          <div className="grid grid-cols-1 gap-16 lg:gap-64 lg:grid-cols-2 mt-16">
            <Form.Item name="textoDream" initialValue={page?.textoDream}>
              <Input.TextArea autoSize />
            </Form.Item>
            <UploadForm
              Form={Form}
              field="fotoDream"
              imgUrl={`${url}${page?.fotoDream?.url}`}
            />
          </div>
        </div>
        <div
          className="mt-16 py-8 px-10 border-solid border-4 border-pink-800"
          style={{ borderRadius: 20 }}
        >
          <center>
            <h3>LINHA BIVVER CLUB</h3>
          </center>
          <div className="grid grid-cols-1 gap-16 lg:gap-64 lg:grid-cols-2 mt-16">
            <UploadForm
              Form={Form}
              field="fotoClub"
              imgUrl={`${url}${page?.fotoClub?.url}`}
            />
            <Form.Item name="textoClub" initialValue={page?.textoClub}>
              <Input.TextArea autoSize />
            </Form.Item>
          </div>
        </div>
        <div
          className="mt-16 py-8 px-10 border-solid border-4 border-yellow-500"
          style={{ borderRadius: 20 }}
        >
          <center>
            <h3>LINHA EXCLUSIVITY</h3>
          </center>
          <div className="grid grid-cols-1 gap-16 lg:gap-64 lg:grid-cols-2 mt-16">
            <Form.Item
              name="textoExclusivity"
              initialValue={page?.textoExclusivity}
            >
              <Input.TextArea autoSize />
            </Form.Item>
            <UploadForm
              field="fotoExclusivity"
              Form={Form}
              imgUrl={`${url}${page?.fotoExclusivity?.url}`}
            />
          </div>
        </div>
        <Form.Item>
          <Button
            className="mt-10"
            style={{ backgroundColor: "orange", color: "white" }}
            htmlType="submit"
          >
            {loadingSave && <LoadingOutlined />} Salvar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
